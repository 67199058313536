@use '@flywheel-io/vision' as vision;

@include vision.icons; // adds the icon font face and glyph classes
@include vision.overlay;

// optional inclusions
@include vision.mat-theme; // uses the vision angular material theme
@include vision.global-typography; // adds global styles for text elements
@include vision.global-scrollbar;
@import '@angular/cdk/overlay-prebuilt.css';

body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
